import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {} from "../reducers/login";
import {
  loginSlice,
  baseSlice,
  pushMessageSlice,
  distionaryUsersSlice,
  statisticSlice,
  userSlice,
} from "../reducers";

export const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    base: baseSlice.reducer,
    push: pushMessageSlice.reducer,
    distionaryUsers: distionaryUsersSlice.reducer,
    statistic: statisticSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
