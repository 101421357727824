import { createBrowserRouter } from "react-router-dom";
import { DefaultLayout } from "../../components/Skeleton";
import { RequireAuth } from "../protectedRoute";
import { Admins } from "../../components/Users";
import { userLoader, messageLoader, statisticLoader } from "../loader";
import { Profile } from "../../components/Profile";
import { Messages } from "../../components/PushMessage";
import { Statistic } from "../../components/Statistic";
import { LoginPage } from "../../components/Login";

export const router = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <DefaultLayout />
      </RequireAuth>
    ),
    errorElement: null,
    path: "/",
    children: [
      {
        element: <Admins />,
        loader: userLoader,
        path: "users",
      },
      {
        element: <Profile />,
        path: "profile",
      },
      {
        element: <Messages />,
        loader: messageLoader,
        path: "messages",
      },
      {
        element: <Statistic />,
        loader: statisticLoader,
        path: "statistic",
      },
    ],
  },
  {
    children: [
      {
        element: <LoginPage />,
        path: `login`,
      },
      {
        path: `forgot-password`,
      },
      {
        path: `forgot-password-confirm`,
      },
    ],
  },
  {
    path: "*",
  },
]);
