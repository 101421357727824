export const TextObj = {
  SERVER_TIMEOUT_ERROR_TEXT: "A szerver jelenleg nem elérhető.",
  INVALID_PARAMETERS: "Nem megfelelő paraméterek.",
  NO_RIGHTS: "Nincs joga a művelethez.",
  SESSION_EXPIRED: "Lejárt a munkamenet, kérem jelentkezzen be újra.",
  INVALID_EMAIL_PASSWORD: "Hibás email / jelszó.",
  NEW_USER: "Új felhasználó",
  CREATE: "Létrehozás",
  EMAIL: "Email",
  PASSWORD: "Jelszó",
  PASSWORD_CHECK: "Jelszó megerősítés",
  FORM_EMAIL_NAME: "email",
  EMAIL_INVALID_ERROR: "Kérlek adj meg egy érvényes emailt!",
  FORM_PASSWORD_NAME: "password",
  TEXT_INPUT_TYPE: "text",
  PASSWORD_INVALID_ERROR:
    "A jelszónak legalább 8 karakter hosszúnak kell lennie, és tartalmaznia kell legalább egy kisbetűt, egy nagybetűt és egy számot.",
  PASSWORD_CHECK_INVALID_ERROR: "A két jelszó nem egyezik",
  SAVE: "Mentés",
  CANCEL: "Mégse",
  PRIMARY_BUTTON_TYPE: "primary",
  SUBMIT_HTML_BUTTON_TYPE: "submit",
  SAVE_BUTTON_KEY: "save",
  CANCEL_BUTTON_KEY: "cancel",
  MESSAGES_TITLE: "Cím",
  TITLE: "title",
  MESSAGES_MESSAGE_LABEL: "Üzenet",
  MESSAGES_MESSAGE: "message",
  MESSAGES_CREATED_LABEL: "Létrehozva",
  MESSAGES_CREATED: "createds",
  DELETE: "delete",
  MESSAGES_POPUP_CONFIRM_TEXT: "Biztos vagy benn hogy törlöd ezt az üzenetet?",
  YES: "Igen",
  NO: "Nem",
  DELETE_LABEL: "Törlés",
  MODIFY_LABEL: "Módosítva",
  MODIFY: "modify",
  UPDATE: "update",
  ADMINS_POPUP_CONFIRM_TEXT: "Biztos vagy benn hogy törlöd ezt a felhasználót?",
  MESSAGE_SEELCTOR: "Válassz ki legalább egy e-mailt!",
  BASE_CHANNEL: "Értesítések",
  MAINTENANCE_CHANNEL: "Karbantartási értesítések",
  SUBSCRIPTION_CHANNEL: "Előfizetés értesítések",
  INSTITUTIONAL_CHANNEL: "Intézményi értesítések",
  CHANNEL_SELECTOR_PLACEHOLDER: "Válaszd ki az értesítés típusát!",
  MESSAGES_TYPE_LABEL: "Típus",
  TYPE: "type",
  MESSAGES_TITLE_ERROR: "Kérlek add meg az üzenet címét!",
  DESCRIPTION: "description",
  MESSAGES_DESCRIPTION_ERROR: "Kérlek adj meg egy üzenetet!",
  TEXT_AREA: "textarea",
  CREATE_USER_ERROR: "A felhasználó ezzel az email címmel már létezik!",
  COMPARE: "Összehasonlítás:",
  DATE_SORT_DATA_SEARCH: "Dátum szerinti szűrés",
  RANGE_SORT_DATA_SEARCH: "Időintervallum szerinti szűrés",
  BAGINNING_DATE: "Kezdődátum",
  FINISH_DATE: "Végdátum",
  PRIORITY_SEARCH: "Prioritás alapú keresés",
  OS_SEARCH: "OS alapú keresés",
  USER_SEARCH: "Felhasználó alapú keresés",
  DAILY: "Napi üzenetek",
  SECOND_DAY: "Másnapi üzenetek",
  THIRD_DAY: "Harmadnapi üzenetek",
  WEEKLY: "Heti üzenetek",
  SECOND_WEEK: "Kétheti üzenetek",
  MONTHLY: "Havi üzenetek",
  SECOND_MONTH: "Kéthavi üzenetek",
  QUARTER_YEAR: "Háromhavi üzenetek",
  CASE_ONE: "Letöltötték, beléptek, de mást nem csináltak az appban (P4)",
  CASE_TWO: "Kosárba raktak terméket, de nem hagyták jóvá az előfizetést (P1)",
  CASE_THREE:
    "Rákattintottak valamelyik szótárcsomagra (megnézték a leírását), de nem fizettek elő (P2)",
  CASE_FOUR:
    "Korábban előfizettek valamire, ami lejárt, és most nincs aktív előfizetésük (P3)",
  ALL_SENT_MESSAGE: "Összesen elküldött üzenetek:",
  ALL_OPENED_MESSAGE: "Összesen megnyitott üzenetek:",
  DAILY_OPEN: "Napi százalékos megnyitás:",
  SECOND_DAY_OPEN: "Másnapi százalékos megnyitás:",
  THIRD_DAY_OPEN: "Harmadnapi százalékos megnyitás:",
  WEEKLY_OPEN: "Heti százalékos megnyitás:",
  SECOND_WEEK_OPEN: "Kétheti százalékos megnyitás:",
  MONTHLY_OPEN: "Havi százalékos megnyitás:",
  SECOND_MONTH_OPEN: "Kéthavi százalékos megnyitás:",
  QUARTER_YEAR_OPEN: "Háromhavi százalékos megnyitás:",
  REQUIRED_DATA: "Töltsd ki a fenti adatokat!",
  NO_DATA: "A megadott dátumra jelenleg nincs bejegyzés!",

  DAILY_FAILED: "Napi sikertelen üzenetek száma:",
  SECOND_DAY_FAILED: "Másnapi sikertelen üzenetek száma:",
  THIRD_DAY_FAILED: "Harmadnapi sikertelen üzenetek száma:",
  WEEKLY_FAILED: "Heti sikertelen üzenetek száma:",
  SECOND_WEEK_FAILED: "Kétheti sikertelen üzenetek száma:",
  MONTHLY_FAILED: "Havi sikertelen üzenetek száma:",
  SECOND_MONTH_FAILED: "Kéthavi sikertelen üzenetek száma:",
  QUARTER_YEAR_FAILED: "Háromhavi sikertelen üzenetek száma:",

  PRIORITY: "Prioritás",
  MESSAGEID: "messageId",
  FIRST_PRIO: "Prio 1",
  SECOND_PRIO: "Prio 2",
  THIRD_PRIO: "Prio 3",
  FOURTH_PRIO: "Prio 4",

  ITERATION: "Iteráció",
  DAILY_TYPE: "Napi",
  SECOND_DAY_TYPE: "Másnapi",
  THIRD_DAY_TYPE: "Harmadnapi",
  WEEKLY_TYPE: "Heti",
  SECOND_WEEK_TYPE: "Kétheti",
  MONTHLY_TYPE: "Havi",
  SECOND_MONTH_TYPE: "Kéthavi",
  THIRD_MONTH_TYPE: "Negyedévi",

  SEARCH: "Kereséshez kezdj el írni..",
  END_DATE_CANNOT_BE_LOWER: "A végdátum nem lehet kisebb mint a kezdő dátum!",
};
