import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  UserOutlined,
  MessageOutlined,
  AreaChartOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { logout } from "../../reducers/login";
const { Sider } = Layout;

export const LeftSideMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(1);

  const handleSelect = (event) => {
    setSelectedKey(event.key);
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/users":
        setSelectedKey(1);
        break;
      case "/messages":
        setSelectedKey(2);
        break;
      case "/statistic":
        setSelectedKey(2);
        break;
      default:
        setSelectedKey(null);
        break;
    }
  }, [location.pathname]);

  const logoutAction = async () => {
    dispatch(logout());
  };

  const menuItems = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: (
        <NavLink to="/users" className="link-color">
          Felhasználók
        </NavLink>
      ),
    },
    {
      key: "2",
      icon: <MessageOutlined />,
      label: (
        <NavLink to="/messages" className="link-color">
          Üzenetek
        </NavLink>
      ),
    },
    {
      key: "3",
      icon: <AreaChartOutlined />,
      label: (
        <NavLink to="/statistic" className="link-color">
          Statisztika
        </NavLink>
      ),
    },
    {
      key: "14",
      icon: <LogoutOutlined />,
      label: (
        <NavLink className="link-color" to="/login" onClick={logoutAction}>
          Kijelentkezés
        </NavLink>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", zIndex: 999, position: "absolute" }}>
      <Sider
        trigger={null}
        style={{
          background: "#151917",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onSelect={handleSelect}
          style={{
            background: "#151917",
            zIndex: 999,
            minHeight: "100%",
          }}
          subMenuOpenDelay={0}
          forceSubMenuRender
          items={menuItems}
        />
      </Sider>
    </Layout>
  );
};
